const AboutCard = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center align-items-center">
        <div className="col col-10 col-lg-5 col-offset-lg-1">
          <div className="jumbotron">
            <h1
              className="display-4"
              style={{
                fontFamily: ['Oxygen', 'sans-serif'],
                fontWeight: '700',
              }}
            >
              Sobre a Gauge
            </h1>
            <p className="lead">
              Buscamos aumentar a eficiência de seu processo industrial através
              do monitoramento e controle dos parâmetros produtivos. Atuamos
              desde a escolha e instalação de pontos de sensoriamento, passando
              por melhorias de controle e automação, até a disponibilização
              desses dados em uma plataforma <em>online</em>. É a indústria 4.0
              mais próxima de todos os produtores.
            </p>
            <hr />
            <p>
              A Gauge Tecnologia é uma empresa da área de automação industrial,
              com foco no mercado agroindustrial gaúcho, fundada por dois
              engenheiros químicos da{' '}
              <a href="http://www.ufrgs.br/ufrgs/inicial">UFRGS</a> e por um
              graduando em engenharia elétrica da{' '}
              <a href="https://www.ufsm.br/">UFSM</a>. Com fundamentação sólida
              em eletrônica, automação industrial e engenharia de processos,
              podemos avaliar criticamente sua atividade produtiva e identificar
              os ganhos em potencial.
            </p>
          </div>
        </div>
        <div className="col col-10 col-lg-5 col-offset-lg-1">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <img
                src="../img/gauge-branco-expand.png"
                alt="Gauge logo"
                className="col col-10 img-fluid"
                style={{ width: '100%' }}
              ></img>
              <a href="#contato" className="btn btn-secondary col col-6">
                Entre em contato
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCard;
