import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-sm navbar-dark fixed-top"
      style={{ backgroundColor: '#00316c' }}
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src="../img/gauge-logo-branco-no-azul.png"
            alt="Gauge"
            height="40"
          />
          <span className="container">Gauge</span>
        </Link>
        <button
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-links"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar-links">
          <div className="navbar-nav">
            <Link to="/" className={`nav-item nav-link`}>
              Início
            </Link>
            <a href="#projetos" className={`nav-item nav-link`}>
              Projetos
            </a>
            <a href="#sobre" className={`nav-item nav-link`}>
              Sobre
            </a>
            {/* <Link to="blog" className={`nav-item nav-link`}>
              Blog
            </Link> */}
            <a href="#contato" className={`nav-item nav-link`}>
              Contato
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
