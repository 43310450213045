import SolutionCard from './SolutionCard.js';

const SolutionsCard = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-10">
          <h1
            className="display-4 text-center mt-4 mb-4"
            style={{
              fontFamily: ['Oxygen', 'sans-serif'],
              fontWeight: '700',
            }}
          >
            Projetos
          </h1>
        </div>
      </div>
      <div className="row justify-content-center">
        <SolutionCard
          titulo="Intrumentação"
          descricao="Não é possível controlar o que não é medido! Projeto de instalação de sensores de temperatura, pressão e vazão."
          imagem="../img/noun_Thermometer_2344589.svg"
        />
        <SolutionCard
          titulo="Equipamentos"
          descricao="Válvulas com acionamento automático e com ajuste de posição. Variação de velocidade de máquinas rotativas."
          imagem="../img/noun_valve_907799.svg"
        />
        <SolutionCard
          titulo="Monitoramento"
          descricao="Sistema SCADA com interface local e possibilidade de acionamento remoto via internet. Tudo isso de forma simples e antes de tudo, segura."
          imagem="../img/noun_chart_581481.svg"
        />
      </div>
    </div>
  );
};

export default SolutionsCard;
