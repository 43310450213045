const IconCredits = () => {
  return (
    <div className="dropdown">
      <button
        className="btn btn-outline-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Links para créditos
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <a
            className="dropdown-item"
            href="https://images.unsplash.com/photo-1528823872057-9c018a7a7553?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80"
          >
            Vinícola por Daniel Vogel (@vogel11)
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="https://thenounproject.com/search/?q=valve&i=907799"
          >
            Valve icon by Ben Davis, RO
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="https://thenounproject.com/search/?q=chart&i=581481"
          >
            Chart icon by Hea Poh Lin, MY
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="https://thenounproject.com/search/?q=thermometer&i=2344589"
          >
            Thermometer icon by Paulius B., LT
          </a>
        </li>
      </ul>
    </div>
  );
};

export default IconCredits;
