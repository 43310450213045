const HomeCard = () => {
  return (
    <div>
      <div
        className="jumbotron container-fluid"
        style={{
          backgroundImage: `url('../img/vinicola.jpg')`,
          height: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          color: '#f9f9fa',
        }}
      >
        <div className="row top-buffer">
          <h1
            className="display-4 col col-md-6"
            style={{
              fontFamily: ['Oxygen', 'sans-serif'],
              fontWeight: '700',
            }}
          >
            Gauge Tecnologia
          </h1>
        </div>
        <div className="row align-items-center bottom-buffer">
          <p className="lead col col-md-5 mt-5" style={{ fontWeight: '700' }}>
            Telemetria, acompanhamento de processos industriais e IIoT voltado à
            cervejaria e vinicultura
          </p>
        </div>
        {/* <div className="row justify-content-md-start align-items-center bottom-buffer">
          <span className="py-1 col col-6 offset-3 col-md-2 offset-md-1">
            <button className="btn btn-outline-primary">
              Melhorias operacionais
            </button>
          </span>
          <span className="py-1 col col-6 offset-3 col-md-2 offset-md-1">
            <button className="btn btn-outline-primary">
              Monitoramento e controle
            </button>
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default HomeCard;
