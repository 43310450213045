import IconCredits from './IconCredits';

const Footer = () => {
  return (
    <div
      className="container-fluid mt-4"
      style={{ background: '#3a4047', color: '#f9f9fa' }}
    >
      <div className="container">
        <div className="row justify-content-start">
          <div className="col col-8 col-md-6 my-2">
            <h4 className="display-4">Redes sociais</h4>
            <a href="https://www.facebook.com/gaugeiiot">
              <img
                src="../img/facebook.svg"
                alt="https://www.facebook.com/gaugeiiot"
                className="img-fluid mx-2"
                style={{ width: '50px' }}
              />
            </a>
            <a href="https://www.instagram.com/gauge_iiot/">
              <img
                src="../img/instagram.svg"
                alt="https://www.instagram.com/gauge_iiot/"
                className="img-fluid mx-2"
                style={{ width: '50px' }}
              />
            </a>
          </div>
          <div className="col col-8 col-md-6 my-2">
            <h4 className="display-4">Contato</h4>
            <p className="lead">Email: engenharia.gauge@gmail.com</p>
            <p className="lead">Telefone: (54) 99625-5737</p>
            <p className="lead">
              Endereço: Rua Assis Brasil n° 3 - Sala 901. Bento Gonçalves - RS
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-4 text-center">
            <IconCredits />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
