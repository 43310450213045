import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './components/Navbar.js';
import HomeCard from './components/HomeCard.js';
import SolutionsCard from './components/SolutionsCard.js';
import AboutCard from './components/AboutCard.js';
import Footer from './components/Footer.js';
import Collaborations from './components/Collaborations.js';

import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Route path="/" exact>
        <div id="inicio">
          <HomeCard />
        </div>
        <div id="projetos">
          <SolutionsCard />
        </div>
        <div className="container">
          <hr />
        </div>
        <div id="sobre">
          <AboutCard />
        </div>
        <div className="container">
          <hr />
        </div>
        <Collaborations />
        <div id="contato"></div>
      </Route>
      <Route path="/blog"></Route>
      <Footer />
    </Router>
  );
}

export default App;
