const Collaborations = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-4">
          <h1
            className="display-4 text-center mt-4 mb-4"
            style={{ fontFamily: ['Oxygen', 'sans-serif'], fontWeight: '700' }}
          >
            Colaboração
          </h1>
        </div>
      </div>
      <div className="row justify-content-center text-center">
        <div className="col col-4">
          <img
            src="../img/29151508-logomarca-fapergs-vertical-png.png"
            alt="Fapergs"
            className="img-fluid"
            style={{ width: '100%' }}
          />
        </div>
        <div className="col col-4 offset-2">
          <img
            src="../img/ufrgs3.jpg"
            alt="UFRGS"
            className="img-fluid"
            style={{ width: '60%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Collaborations;
