import PropTypes from 'prop-types';

const SolutionCard = (props) => {
  return (
    <div className="card my-3 mx-3" style={{ width: '250px' }}>
      <div className="container w-50 mt-4">
        <img
          src={props.imagem}
          alt="Valve"
          className="card-img-top img-fluid"
        />
      </div>
      <div className="card-body">
        <h5 className="card-title" style={{ fontWeight: '700' }}>
          {props.titulo}
        </h5>
        <p className="card-text">{props.descricao}</p>
        {/* <Link className="btn btn-primary" to="/">
          Saiba mais
        </Link> */}
      </div>
    </div>
  );
};

SolutionCard.propTypes = {
  titulo: PropTypes.string,
  descricao: PropTypes.string,
  imagem: PropTypes.string,
};

export default SolutionCard;
